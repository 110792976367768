import * as deepmerge from 'deepmerge';

import { ENVIRONMENT_GLOBAL } from '../global_env';

export const environment = deepmerge(ENVIRONMENT_GLOBAL, {
  envName: 'dev',
  production: true,
  matomoSrc: 'https://matomo.meinhplus.de/statistic/js/container_EfEzdlyc_dev_831007dcb8e74d63136d1def.js',
  redirect: {
    host: 'https://dev-dashboard.meinhplus.de'
  },
  auth_L1: {
    client_id: 'customerportal',
    scope: 'phone email profile openid',
    endpoint: {
      host: 'https://dev-account.meinhplus.de',
      paths: {
        prefix: '/auth/realms/hekatron-tuer-tor'
      }
    }
  },
  backend: {
    scope: 'hcp',
    client_id: 'hcp-webbapp-cH0w6GlrnNn5mkXn',
    endpoint: {
      host: 'https://api.dev-dashboard.meinhplus.de'
    }
  },
  files: {
    paths: {
      host: 'https://dev-dashboard.meinhplus.de' //TODO check
    }
  },
  links: {
    applications: {
      mh2: {
        host: 'https://dev.meinhplus.de'
      },
      l2: {
        host: 'https://dev-administration.meinhplus.de'
      },
      oms: {
        host: 'https://dev-oms.meinhplus.de'
      }
    }
  }
});
